<template>
  <div>
    <b-modal
      id="admin-state-modal"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('StateChange')"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-select-1"
          :label="$t('State')"
          label-for="select-1"
        >
          <vue-select
            v-model="$v.form.teamName.$model"
            :options="filteredOptions"
            :placeholder="$t('SelectState')"
            label="text"
            :reduce="(e) => e.value"
            :state="validateState('teamName')"
            aria-describedby="input-1-live-feedback"
            required
          />
        </b-form-group>
        <b-form-group
          id="input-select-1"
          :label="$t('Notes')"
          label-for="select-1"
        >
          <b-textarea
            v-model="form.notes"
            :placeholder="$t('Notes')"
            label="name"
            aria-describedby="input-1-live-feedback"
            required
          />
        </b-form-group>
        <b-form-group />
        <p style="padding-top: 15px;">
          <span style="color: red;">{{ $t("Warning") }}</span> <span style="font-weight: 500;">{{ $t("WarningS") }}</span>
        </p>
        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="primary"
            class="buttonSubmit"
            style="margin-top: 10px;"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 10px; background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate';
import {
  required,
}
from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  props: ['selectedState'],
  data() {
    return {
      form: {
        teamName: '',
        notes: '',
      },
      rows: [],

    }
  },
  validations: {
    form: {
      teamName: {
        required,
      },
    },
  },
  computed: {

    ...mapGetters(['getTeamsNames', 'getOrderItemStates']),

    formattedTeams() {
      return this.getTeamsNames.map((team) => {
        const countItems = team.countItems === null ? 0 : team.countItems;
        const label = `${team.teamName} - Active Orders ${countItems} `;
        return {
          label,
          value: team.teamNameId,
          teamName: team.teamName,
          countItems,
        };
      });
    },
    filteredOptions() {
      const orders = this.getOrderItemStates.filter((option) => { return option != 'StockNotAvailable' });
      const options = orders.map((state) => {
        return {
          value: state,
          text: this.$t(state),
        }
      });
      return options;
    },
  },
  watch: {
    selectedState(value) {
      this.form.teamName = value
    },
  },
  mounted() {
    // this.teamNames({
    //   pageNumber: 1,
    //   pageSize: 15,
    // });
  },
  methods: {
    ...mapActions(['teamNames']),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit('state', this.form)
      this.$refs.modal.hide()
      setTimeout(() => {
        this.resetForm()
      }, 10)
    },
    onCancel() {
      this.$refs.modal.hide()
      this.resetForm()
    },
    resetForm() {
      if (this.selectedState == null) {
        this.form.teamName = ''
      } else {
        (
          this.form.teamName = this.selectedState
        )
      }
      this.form.notes = ''
      this.form.description = ''
      this.form.measurementType = ''
    },
  },
}
</script>

<style lang="scss" scoped>
  // form {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   gap: 10px;
  // }
  // .flex-form {
  //   display: flex;
  //   flex-direction: column;
  // }
</style>
